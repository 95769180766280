import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Dashbot’s Word Clouds identify top 100 words used in incoming and outgoing messages.`}</h2>
    <p>{`We provide a variety of reports and charts that offer insight if you investigate a little further. Now with Word Clouds, trends and patterns float to the top so that you don’t have to dig as much. Frequently used words can easily be spotted in Word Clouds given that the size of the word indicates the volume of usage. You can understand how people interpret your brand and get a quick glimpse of overall perspective.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/02/06112535/WordCloud.png",
        "alt": null
      }}></img></p>
    <p><em parentName="p">{`Word Cloud for Gamemonk, a trivia Slack bot`}</em></p>
    <p>{`For example, the incoming messages word cloud above for Gamemonk visually highlights a few important points:`}</p>
    <ol>
      <li parentName="ol">{`Users send in messages that include the word “categories” which indicates the popularity of that specific type of trivia.`}</li>
      <li parentName="ol">{`Users express disappointment with profanity when their trivia answers are incorrect.`}</li>
      <li parentName="ol">{`Users are asking the bot to provide a “hint” which might imply that there needs to be a wider range of difficulty.`}</li>
    </ol>
    <hr></hr>
    <h3>{`About Dashbot`}</h3>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/?utm_source=blog&utm_medium=medium&utm_campaign=productlaunch_wordclouds"
        }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`You can view detailed analytics `}{`&`}{` competitive market metrics, analyze conversation transcripts, track message funnels, optimize referral campaigns, understand sentiment `}{`&`}{` demographics, and add human-in-the-loop to your bot — all in real time.`}</em></p>
    <p><em parentName="p">{`We natively support Facebook Messenger, Twitter, Amazon Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface. Sign up for free at `}<a parentName="em" {...{
          "href": "https://www.dashbot.io/?utm_source=blog&utm_medium=medium&utm_campaign=productlaunch_wordclouds"
        }}>{`www.dashbot.io`}</a>{`.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      